var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding":"0 0"},attrs:{"fluid":""}},[_c('v-container',{staticStyle:{"padding-bottom":"0px"},attrs:{"fluid":""}},[_c('span',{},[_c('div',{staticClass:"display-1 mb-4"},[_c('v-icon',{attrs:{"size":"50","color":"primary"}},[_vm._v("content_paste")]),_c('span',{staticClass:"ml-2"},[_vm._v("Practical Viva ~ Report")])],1)])]),_c('v-divider',{staticClass:"mb-4"}),_c('v-container',{staticStyle:{"padding-top":"0px"},attrs:{"fluid":""}},[_c('v-btn',{staticClass:"elevation-0",staticStyle:{"pointer-events":"none"}},[_c('span',{staticClass:"title"},[_vm._v("Practical:")])]),_c('v-card',[_c('v-layout',[(false)?_c('v-flex',{attrs:{"xs2":""}},[_c('v-layout',{attrs:{"justify-center":"","row":"","fill-height":"","align-center":""}},[_c('v-btn',{staticStyle:{"pointer-events":"none"},attrs:{"block":"","flat":""}},[_c('span',{staticClass:"title"},[_vm._v("Practical:")])])],1)],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{staticClass:"ma-2",attrs:{"items":_vm.getPracticalCenterList,"item-text":item => item.center_code + ' - ' + item.center_name,"item-value":"center_code","label":"Select Center","persistent-hint":"","prepend-inner-icon":"library_books","outline":"","hide-details":"","disabled":_vm.ifCenterUser},on:{"change":function($event){return _vm.centerChange()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"color":"#FAFAFA","close":"","selected":data.selected},on:{"input":function($event){_vm.searchParam.center_code = ''}}},[_vm._v(_vm._s(data.item.center_code + " - " + data.item.center_name))])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-tile-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
                      data.item.center_code + ' - ' + data.item.center_name
                    )}})],1)]]}}]),model:{value:(_vm.searchParam.practical_center_code),callback:function ($$v) {_vm.$set(_vm.searchParam, "practical_center_code", $$v)},expression:"searchParam.practical_center_code"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{staticClass:"ma-2",attrs:{"items":_vm.getSubjectListByPrac,"item-text":item => item.subject_code + ' - ' + item.subject_name,"item-value":"subject_code","label":"Select Subject","persistent-hint":"","prepend-icon":"library_books","outline":""},on:{"change":function($event){return _vm.subjectChangeP($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"close":"","selected":data.selected},on:{"input":function($event){_vm.searchParam.practical_subject_code = ''}}},[_vm._v(_vm._s(data.item.subject_code + " - " + data.item.subject_name))])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-tile-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
                      data.item.subject_code + ' - ' + data.item.subject_name
                    )}})],1)]]}}]),model:{value:(_vm.searchParam.practical_subject_code),callback:function ($$v) {_vm.$set(_vm.searchParam, "practical_subject_code", $$v)},expression:"searchParam.practical_subject_code"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{staticClass:"ma-2",attrs:{"items":_vm.getPaperByPractical,"item-text":item => item.paper_code + ' - ' + item.paper_name,"item-value":"paper_code","label":"Select Course","persistent-hint":"","loading":_vm.paperLoading,"prepend-inner-icon":"library_books","outline":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"color":"#FAFAFA","close":"","selected":data.selected},on:{"input":function($event){_vm.searchParam.paper_code = ''}}},[_vm._v(_vm._s(data.item.paper_code + " - " + data.item.paper_name))])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-tile-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
                      data.item.paper_code + ' - ' + data.item.paper_name
                    )}})],1)]]}}]),model:{value:(_vm.searchParam.practical_paper_code),callback:function ($$v) {_vm.$set(_vm.searchParam, "practical_paper_code", $$v)},expression:"searchParam.practical_paper_code"}})],1),_c('v-flex',{attrs:{"xs0":""}},[_c('v-chip',{staticStyle:{"margin-left":"-2em","margin-top":"-.6em"}},[_vm._v(_vm._s(_vm.getPaperByPractical.length))])],1)],1),_c('v-divider',{staticClass:"my-1"}),_c('v-layout',[_c('v-flex',{staticClass:"mx-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.downloadPracticalMarkBlankList()}}},[_vm._v("Practical Mark Blank List Export")])],1),_c('v-flex',{staticClass:"mx-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.downloadPracticalWantingList()}}},[_vm._v("Practical Wanting List Export")])],1)],1)],1)],1),_c('v-container',{staticStyle:{"padding-top":"0px"},attrs:{"fluid":""}},[_c('v-btn',{staticClass:"elevation-0",staticStyle:{"pointer-events":"none"}},[_c('span',{staticClass:"title"},[_vm._v("Viva:")])]),_c('v-card',[_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{staticClass:"ma-2",attrs:{"items":_vm.getVivaCenterList,"item-text":item => item.center_code + ' - ' + item.center_name,"item-value":"center_code","label":"Select Center","persistent-hint":"","prepend-inner-icon":"library_books","outline":"","hide-details":"","disabled":_vm.ifCenterUser},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"color":"#FAFAFA","close":"","selected":data.selected},on:{"input":function($event){_vm.searchParam.center_code = ''}}},[_vm._v(_vm._s(data.item.center_code + " - " + data.item.center_name))])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-tile-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
                      data.item.center_code + ' - ' + data.item.center_name
                    )}})],1)]]}}]),model:{value:(_vm.searchParam.center_code),callback:function ($$v) {_vm.$set(_vm.searchParam, "center_code", $$v)},expression:"searchParam.center_code"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{staticClass:"ma-2",attrs:{"items":_vm.getSubjectListByViva,"item-text":item => item.subject_code + ' - ' + item.subject_name,"item-value":"subject_code","label":"Select Subject","persistent-hint":"","prepend-icon":"library_books","outline":""},on:{"change":function($event){return _vm.subjectChangeV($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"close":"","selected":data.selected},on:{"input":function($event){_vm.searchParam.subject_code = ''}}},[_vm._v(_vm._s(data.item.subject_code + " - " + data.item.subject_name))])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-tile-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
                      data.item.subject_code + ' - ' + data.item.subject_name
                    )}})],1)]]}}]),model:{value:(_vm.searchParam.subject_code),callback:function ($$v) {_vm.$set(_vm.searchParam, "subject_code", $$v)},expression:"searchParam.subject_code"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{staticClass:"ma-2",attrs:{"items":_vm.getVivaPaperItem,"item-text":item => item.paper_code + ' - ' + item.paper_name,"item-value":"paper_code","label":"Select Course","persistent-hint":"","prepend-inner-icon":"library_books","outline":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"color":"#FAFAFA","close":"","selected":data.selected},on:{"input":function($event){_vm.searchParam.paper_code = ''}}},[_vm._v(_vm._s(data.item.paper_code + " - " + data.item.paper_name))])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-tile-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
                      data.item.paper_code + ' - ' + data.item.paper_name
                    )}})],1)]]}}]),model:{value:(_vm.searchParam.paper_code),callback:function ($$v) {_vm.$set(_vm.searchParam, "paper_code", $$v)},expression:"searchParam.paper_code"}})],1),_c('v-flex',{attrs:{"xs0":""}},[_c('v-chip',{staticStyle:{"margin-left":"-2em","margin-top":"-.6em"}},[_vm._v(_vm._s(_vm.getVivaPaperItem.length))])],1)],1),_c('v-divider',{staticClass:"my-1"}),_c('v-layout',[_c('v-flex',{staticClass:"mx-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.downloadVivaMarkBlankList()}}},[_vm._v("Viva Mark Blank List Export")])],1),_c('v-flex',{staticClass:"mx-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.downloadVivaWantingList()}}},[_vm._v("Viva Wanting List Export")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }